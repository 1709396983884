import React, { useState } from 'react';
import axios from 'axios';
import Alert from '../../Alert/Alert';
import variables from '../../../Context/Variables';
import SubmitButton from '../../Buttons-loaders-inputs/SubmitButton/SubmitButton';
import styles from "./MercadoPagoButton.module.scss";

const MercadoPagoButton = ({ carritoCompraDto, evento, setCargandoCompra, setErrorCompra, setUsuarioNecesitaAutenticacion }) => {
  const [isLoading, setIsLoading] = useState(false);

  const realizarCompraMp = async () => {
    setCargandoCompra(true);
    setIsLoading(true);
    setErrorCompra("");
    try {
      let publicKey = "APP_USR-6232069b-8a10-467f-b96d-4a76be9ea169";

      if (evento.distribucionDePagos === "DIRECTO") {
        publicKey = evento.publicKeyMpProductor;
      }

      const userDetails =
        sessionStorage.getItem("userDetails") ||
        localStorage.getItem("userDetails");
      if (!userDetails) {
        setUsuarioNecesitaAutenticacion(true);
      }

      const details = JSON.parse(userDetails);
      const token = details.jwt;

      const response = await axios.post(
        variables.API_BASE_URL + "/api/mp/crearYRedireccionar",
        carritoCompraDto,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      window.location.href = data.init_point;
    } catch (error) {
      console.error("Error durante la compra:", error);
      setErrorCompra(error.message || "Ha ocurrido un error desconocido.");
      Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'Error al procesar el pago. Por favor, intente nuevamente.',
        duracion: 4000
      });
    } finally {
      setCargandoCompra(false);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <SubmitButton
        isDisabled={isLoading}
        color="#00796b"
        isLoading={isLoading}
        onClick={realizarCompraMp}
        className={styles.button}
        filling={false}
      >
        <img style={{ width: "30px" }} src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/logomp.png" alt="" />Saldo en Mercado Pago
      </SubmitButton>
    </div>
  );
};

export default MercadoPagoButton;