import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPeopleGroup, faMinus, faPlus, faSpinner, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import styles from './GestionStaff.module.scss';
import variables from "../../Context/Variables";
import Alert from "../../Components/Alert/Alert";
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import './Skeleton/staffSkeleton.css';

const GestionStaff = () => {
    const [staff, setStaff] = useState([]);
    const [newStaffEmail, setNewStaffEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [mostrarPassword, setMostrarPassword] = useState({});
    const navigate = useNavigate();
    const { estaAutenticado, jwt } = useAuth();

    useEffect(() => {
        document.title = "Gestión de Staff";
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!estaAutenticado) {
            navigate("/login");
        } else {
            const details = JSON.parse(userDetails);

            if (details.rol !== "PRODUCTOR") {
                retornarNoPermissionPage();
            }

            fetchStaff(details.jwt);
        }
    }, [navigate, estaAutenticado]);

    const deleteStaff = async (staffId) => {
        const result = await Alert({
            tipo: 'warning',
            titulo: '¿Estás seguro?',
            descripcion: "Esto eliminará al staff seleccionado y todas sus asociaciones.",
            duracion: 0 // 0 para que no se cierre automáticamente
        });

        if (result) {
            try {
                await axios.delete(`${variables.API_BASE_URL}/api/staff/delete/${staffId}`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`
                    }
                });
                
                await Alert({
                    tipo: 'success',
                    titulo: 'Eliminado!',
                    descripcion: 'El staff ha sido eliminado exitosamente.',
                    duracion: 3000
                });
                
                // Actualizar la lista de Staff
                setStaff(staff.filter(member => member.id !== staffId));
            } catch (error) {
                await Alert({
                    tipo: 'error',
                    titulo: 'Error!',
                    descripcion: 'No se pudo eliminar el staff. Asegúrese de que tiene permiso para realizar esta acción.',
                    duracion: 4000
                });
            }
        }
    };

    const validarEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const retornarNoPermissionPage = () => {
        return <NoPermissionPage />;
    }

    const fetchStaff = async (jwt) => {
        try {
            const response = await axios.get(`${variables.API_BASE_URL}/api/staff/list`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });
            setStaff(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching staff', error);
            setError('Failed to fetch staff.');
            setLoading(false);
        }
    };

    const addStaff = async () => {
        if (!validarEmail(newStaffEmail)) {
            await Alert({
                tipo: 'warning',
                titulo: 'Email inválido',
                descripcion: 'Por favor, ingresa un email válido antes de continuar.',
                duracion: 3000
            });
            return;
        }

        const staffData = { email: newStaffEmail };
        setIsAdding(true);
        try {
            const response = await axios.post(`${variables.API_BASE_URL}/api/staff/create`, staffData, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });

            if (response.status === 200) {
                fetchStaff(jwt);
                setNewStaffEmail('');
                setError(null); // Limpiar errores previos si la solicitud fue exitosa
            } else {
                const errorMessage = response.data && response.data.message
                    ? response.data.message
                    : `Error: ${response.status}`;
                setError(errorMessage);
            }
        } catch (error) {
            console.error('Error adding staff', error);

            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : `Error: ${error.message}`;

            setError(errorMessage);
        } finally {
            setIsAdding(false);
        }
    };

    const togglePasswordVisibility = (staffId) => {
        setMostrarPassword(prevState => ({
            ...prevState,
            [staffId]: !prevState[staffId]
        }));
    };

    return (
        <div className={styles.bodyGR}>
            <h1 className={styles.titulo}>Gestión de Staff <FontAwesomeIcon icon={faPeopleGroup} /></h1>
            <p className={styles.descrip}>Desde acá podés agregar, eliminar y visualizar todos tus Staff asociados.</p>
            <div className={styles.listaStaff}>
                <h3 className={styles.subtitulo} style={{ width: "100%" }}>Tus Staff</h3>
                {loading ? (
                    <div className={styles.contSkeletons}>
                        {[...Array(3)].map((_, index) => (
                            <div key={index} className="staffItem">
                                <div className="header">
                                    <div className="contIcono"></div>
                                    <div className="caracteristicaId">
                                        <span></span>
                                        <h3></h3>
                                    </div>
                                </div>
                                <div className="caracteristica">
                                    <span></span>
                                    <h3></h3>
                                </div>
                                <div className="caracteristica">
                                    <div className="passwordContainer">
                                        <div className="passwordInput"></div>
                                        <div className="togglePassword"></div>
                                    </div>
                                </div>
                                <div className="eliminar"></div>
                            </div>
                        ))}
                    </div>
                ) :

                    staff.length > 0 ? staff.map(staffMember => (
                        <div key={staffMember.id} className={styles.staffItem}>
                            <div className={styles.header}>
                                <div className={styles.contIcono}>
                                    <FontAwesomeIcon icon={faUser} />
                                </div>
                                <div className={styles.caracteristicaId}>
                                    <span>ID</span>
                                    <h3>{staffMember.id}</h3>
                                </div>
                            </div>
                            <div className={styles.caracteristica}>
                                <span>Email</span>
                                <h3>{staffMember.email}</h3>
                            </div>
                            <div className={styles.caracteristica}>
                                <span>Contraseña</span>
                                <div className={styles.passwordContainer}>
                                    <input
                                        type={mostrarPassword[staffMember.id] ? "text" : "password"}
                                        value={staffMember.contrasena}
                                        readOnly
                                        className={styles.passwordInput}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => togglePasswordVisibility(staffMember.id)}
                                        className={styles.togglePassword}
                                        aria-label={
                                            mostrarPassword[staffMember.id] ? "Ocultar contraseña" : "Mostrar contraseña"
                                        }
                                    >
                                        {mostrarPassword[staffMember.id] ? (
                                            <FontAwesomeIcon icon={faEyeSlash} />
                                        ) : (
                                            <FontAwesomeIcon icon={faEye} />
                                        )}
                                    </button>
                                </div>
                            </div>
                            <button className={styles.eliminar} style={{ color: "#FF5C5D" }} onClick={() => deleteStaff(staffMember.id)}>Eliminar <FontAwesomeIcon icon={faMinus} /></button>
                        </div>
                    )) : <p style={{ color: "red", margin: "40px auto", textAlign: "center" }}>Aún no tienes Staff.</p>}
            </div>
            {error && <p className="error">{error}</p>}
            <h3 className={styles.subtitulo}>Agregar Staff</h3>
            <div className={styles.agregarCont}>
                <div className={styles.iconInputCont}>
                    <FontAwesomeIcon icon={faUser} />
                    <input type="text" placeholder="Email del nuevo Staff" value={newStaffEmail} onChange={e => setNewStaffEmail(e.target.value)} />
                </div>

                <button onClick={addStaff} disabled={isAdding}>
                    {isAdding ? <FontAwesomeIcon icon={faSpinner} spin /> : <>Agregar <FontAwesomeIcon icon={faPlus} /></>}
                </button>
            </div>

        </div>
    );
};

export default GestionStaff;