import React, { useState } from "react";
import styles from "./LoginForm.module.scss";
import { useAuth } from "../../../Context/AuthProvider";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import Alert from "../../Alert/Alert";
import "sweetalert2/src/sweetalert2.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import SubmitButton from "../../Buttons-loaders-inputs/SubmitButton/SubmitButton";
import variables from "../../../Context/Variables";
import LoaderBolas from "../../Buttons-loaders-inputs/LoaderGoogle/LoaderBolas";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mantenerSesion, setMantenerSesion] = useState(false);
  const [error, setError] = useState("");
  const [mostrarPassword, setMostrarPassword] = useState(false);
  const { login } = useAuth();
  const [isLoading,setIsLoading] = useState(false);
  const [googleLoading,setGoogleLoading] = useState(false); 

  const apiUrl = variables.API_BASE_URL  + "/api/usuarios/login";

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isFormValid()) return;

    setIsLoading(true);
    try {
      const response = await axios.post(apiUrl, { email, password });
      login(response.data, mantenerSesion);
      Alert({
        tipo: "success",
        titulo: "Ingreso exitoso",
        descripcion: "Has ingresado exitosamente. Podés continuar con tu compra.",
        duracion: 5000
      });
      const event = new CustomEvent("userAuthenticated", {
        detail: response.data.jwt,
      });
      window.dispatchEvent(event);
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 401) {
        // Si el servidor devuelve un estado 401, maneja específicamente este error.
        setError("Email o contraseña incorrecta");
      }
      if (error.response && error.response.status === 403) {
        // Si el servidor devuelve un estado 401, maneja específicamente este error.
        setError("Ingrese desde su cuenta de Google");
      }
      if (error.response && error.response.status === 400) {
        // Si el servidor devuelve un estado 401, maneja específicamente este error.
        setError("No existe un usuario con ese email");
      } else {
        // Para todos los demás errores, muestra un mensaje genérico.
        setError("Ocurrió un error, vuelva a intentarlo.");
      }
    }finally{
      setIsLoading(false);
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isFormValid = () => {
    return isValidEmail(email) && password.length > 0;
  };

  const togglePasswordVisibility = () => {
    setMostrarPassword(!mostrarPassword);
  };

  function handleError() {
    setError(
      "No se pudo iniciar sesión con Google. Por favor, inténtalo de nuevo."
    );
  }

  const handleGoogleLogin = async (googleData) => {
    setGoogleLoading(true)
    const apiUrl = (variables.API_BASE_URL + "/api/usuarios/authGoogle");
    try {
      // Enviar el token al backend
      const response = await axios.post(apiUrl, {
        tokenId: googleData.credential, 
      });

      // Si el backend valida el token y encuentra/crea el usuario correctamente
      if (response.data && response.data.jwt) {
        login(response.data, mantenerSesion);
        Alert({
          tipo: "success",
          titulo: "Ingreso exitoso",
          descripcion: "Has ingresado con Google exitosamente. Podés continuar con tu compra.",
          duracion: 5000
        });
        const event = new CustomEvent("userAuthenticated", {
          detail: response.data.jwt,
        });
        window.dispatchEvent(event);
        setGoogleLoading(false);
      } else {
        // Maneja casos donde la respuesta no es la esperada
        setError(
          "No se pudo iniciar sesión con Google. Por favor, inténtalo de nuevo."
        );
        setGoogleLoading(false);
      }
    } catch (error) {
      // Manejar errores de la petición
      console.error("Error al iniciar sesión con Google:", error);
      setError(
        "Error al iniciar sesión con Google. Por favor, inténtalo de nuevo."
      );
      setGoogleLoading(false);
    }
  };

  return (
    <div className={`${styles.loginForm} ${styles.parteFormLogin}`}>
      <h3>Ingresa a tu cuenta</h3>
      <div className={styles.contLoguinGoogle}>
      {googleLoading ? <LoaderBolas /> : <GoogleLogin onSuccess={handleGoogleLogin} onError={handleError} className={styles.google} />}
      </div>
      <small className={styles.o}>o</small>
      <form
        onSubmit={handleSubmit}
        autoComplete="on"
        className={styles.formulario}
      >
        {error && <div className={styles.errorMesagge}>{error}</div>}

        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          placeholder="ejemplo@mail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="username"
          className={styles.formControl}
        />

        <label htmlFor="password">Contraseña</label>
        <div className={styles.inputGroup}>
          <input
            type={mostrarPassword ? "text" : "password"}
            id="password"
            placeholder="Ingresa tu contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            className={styles.passwordInput}
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className={styles.togglePassword}
            aria-label={
              mostrarPassword ? "Ocultar contraseña" : "Mostrar contraseña"
            }
          >
            {mostrarPassword ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </button>
        </div>

        <div className={styles.containerCheckbox}>
          <input
            type="checkbox"
            id="mantenerSesion"
            checked={mantenerSesion}
            onChange={(e) => setMantenerSesion(e.target.checked)}
          />
          <label htmlFor="mantenerSesion" className={styles.checkboxLabel}>
            <svg viewBox="0 0 64 64" height="1em" width="1em">
              <path
                d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                className={styles.path}
              ></path>
            </svg>
            Permanecer conectado
          </label>
        </div>

        <SubmitButton
            isDisabled={!isFormValid() || isLoading}
            color="hsl(0, 0%, 15%)" // El color deseado para el botón
            isLoading={isLoading}
            onClick={handleSubmit} // Este onClick podría no ser necesario ya que es un botón de tipo submit
          >
            Iniciar sesión
          </SubmitButton>
      </form>
    </div>
  );
};

export default LoginForm;
