import React, {useEffect} from 'react';
import styles from './Nosotros.module.scss';
import WhatsAppButton from '../../Components/Buttons-loaders-inputs/WhatsAppButton/WhatsAppButton ';
import EmailButton from '../../Components/Buttons-loaders-inputs/EmailButton/EmailButton';

const Nosotros = () => {
  //Modificacion de head
  useEffect(() => {
    document.title = "Nosotros - simplepass";
  }, []);

  return (
    <section className={styles.nosotrosBody}>
      <div className={styles.nosotrosCuerpo + " " + styles.columnCenter}>
        <h1 className={styles.titulo}>¿Quiénes somos?</h1>
        <h3>Simplepass, con sede en Entre Ríos, se destaca como una <b>compañía pionera en la implementación de la venta online de boletos y consumiciones a través de códigos QR</b>. Hernán y Octavio, dos emprendedores apasionados por la tecnología y la creación de soluciones prácticas, fundaron la empresa con un enfoque claro: proporcionar productos que aporten un valor genuino a sus usuarios. Nuestra misión principal es trabajar en estrecha colaboración con reconocidos productores de eventos y organizadores de fiestas, siempre con la mirada puesta en la innovación constante, con el fin de impulsar de manera significativa y efectiva la evolución de la industria.</h3>
      
        <div className={styles.lineaDivisora}></div>
        <h2 className={styles.subtitulo}>Nuestra misión</h2>
        <h3>Hacer que la experiencia de compra de tickets para eventos sea simple, accesible y emocionante para todos. Nos esforzamos por brindar una plataforma fácil de usar y segura para que nuestros clientes puedan descubrir y comprar boletos para una amplia variedad de eventos de manera rápida y sencilla.</h3>
        
        <h2 className={styles.subtitulo}>¿Qué servicios ofrecemos?</h2>
        <ul>
          <li>Venta de tickets QR para todo tipo de eventos.</li>
          <li>Venta de consumiciones.</li>
        </ul>
        <span className={styles.subtitulo}>¿Te gustaría saber más? Comunicate con nosotros sin dudarlo!</span>
        <WhatsAppButton phoneNumber="+5493436114446" message="Hola, estoy interesado en sus servicios. ¿Podrían darme más información?" />
        <EmailButton emailAddress="simplesistemasweb@gmail.com" subject="Consulta sobre sus servicios" body="Hola, estoy interesado en obtener más información sobre sus servicios." />

      </div>
    </section>
  );
};

export default Nosotros;
