import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPeopleGroup, faMinus, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './GestionRrpps.module.scss';
import './Skeleton/rrppSkeleton.css';
import variables from "../../Context/Variables";
import Alert from '../../Components/Alert/Alert';
import Swal from 'sweetalert2';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';

const GestionRrpps = () => {
  const [rrpps, setRrpps] = useState([]);
  const [newRrppName, setNewRrppName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isAdding, setIsAdding] = useState(false);
  const { estaAutenticado, jwt } = useAuth();

  useEffect(() => {
    document.title = "Gestión RRPPs";
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!estaAutenticado) {
      navigate("/login");
    } else {
      const details = JSON.parse(userDetails);

      if (details.rol !== "PRODUCTOR") {
        retornarNoPermissionPage();
      }

      fetchRrpps(details.jwt);
    }
  }, [navigate]);

  const retornarNoPermissionPage = () => {
    return <NoPermissionPage />;
  }

  const fetchRrpps = async (jwt) => {
    try {
      const response = await axios.get(`${variables.API_BASE_URL}/api/rrpps/listDetails`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      setRrpps(response.data);
      console.log("RRPPS: " + response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching RRPPs', error);
      setError('Failed to fetch RRPPs.');
      setLoading(false);
    }
  };

  const addRrpp = async () => {
    const rrppData = { nombre: newRrppName };
    setIsAdding(true);
    try {
      const response = await axios.post(`${variables.API_BASE_URL}/api/rrpps/users/addRrpp`, rrppData, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });

      if (response.status === 200) {
        fetchRrpps(jwt);
        setNewRrppName('');
        setError(null); // Limpiar errores previos si la solicitud fue exitosa
      } else {
        // Si la respuesta no es 200, maneja el error
        const errorMessage = response.data && response.data.message
          ? response.data.message
          : `Error: ${response.status}`;
        setError(errorMessage);
      }
    } catch (error) {
      console.error('Error adding RRPP', error);

      // Obtener mensaje de error específico del backend
      const errorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : `Error: ${error.message}`;

      setError(errorMessage);
    } finally {
      setIsAdding(false);
    }
  };

  const deleteRrpp = async (rrppId) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Esto desvinculará al representante de relaciones públicas (RRPP) del usuario actual.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, desvincular!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(`${variables.API_BASE_URL}/api/rrpps/${rrppId}/unassignUser`, {}, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(response => {
          Alert({
            tipo: 'success',
            titulo: 'Desvinculado!',
            descripcion: 'El RRPP ha sido desvinculado exitosamente.',
            duracion: 3000
          }).then(() => {
            // Actualizar la lista de RRPPs
            setRrpps(rrpps.filter(rrpp => rrpp.id !== rrppId));
          });
        }).catch(error => {
          Alert({
            tipo: 'error',
            titulo: 'Error!',
            descripcion: 'No se pudo desvincular el RRPP. Asegúrese de que tiene permiso para realizar esta acción.',
            duracion: 5000
          });
        });
      }
    });
  };


  return (
    <div className={styles.bodyGR}>
      <h1 className={styles.titulo}>Gestion de RRPPs <FontAwesomeIcon icon={faPeopleGroup} /></h1>
      <p className={styles.descrip}>Desde acá podés agregar, eliminar y visualizar todos tus RRPPs, los cuales luego podrás asignar a tus eventos como vendedores oficiales y obtendrán un enlace para comercializarlo.</p>
      <div className={styles.listaRrpps}>
        <h3 className={styles.subtitulo} style={{ width: "100%" }}>Tus RRPPs</h3>
        {loading ? <div className={styles.contSkeletons}>
          <div class="rrppItem">
          </div>
          <div class="rrppItem">
          </div>
          <div class="rrppItem">
          </div>
        </div> :

          rrpps.length > 0 ? rrpps.map(rrpp => (
            <div key={rrpp.id} className={styles.rrppItem}>
              <div className={styles.contNombre}>
                <FontAwesomeIcon icon={faUser} />
                <span>{rrpp.nombre}</span>
              </div>

              <button style={{ color: "#FF5C5D" }} onClick={() => deleteRrpp(rrpp.id)}>Eliminar <FontAwesomeIcon icon={faMinus} /></button>
            </div>
          )) : <p style={{ color: "red", margin: "40px auto", textAlign: "center" }}>Aún no tenes RRPPs.</p>}
      </div>
      {/* Input to add new RRPP */}
      {error && <p className="error">{error}</p>}
      <h3 className={styles.subtitulo}>Agregar RRPP</h3>
      <div className={styles.agregarCont}>
        <div className={styles.iconInputCont}>
          <FontAwesomeIcon icon={faUser} />
          <input type="text" max={20} placeholder="Nombre del nuevo RRPP" value={newRrppName} onChange={e => setNewRrppName(e.target.value)} />
        </div>

        <button onClick={addRrpp} disabled={isAdding}>
          {isAdding ? <FontAwesomeIcon icon={faSpinner} spin /> : <>Agregar <FontAwesomeIcon icon={faPlus} /></>}
        </button>
      </div>

    </div>
  );
};

export default GestionRrpps;