import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Alert from '../../Components/Alert/Alert';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "./CrearMenu.module.scss"
import variables from "../../Context/Variables";
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";

function CrearMenuPage() {
    const [productos, setProductos] = useState([{ id: 1 }]);
    const [loading, setLoading] = useState(false);
    const { eventoId } = useParams(); 
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Crear menú";
        window.scrollTo(0, 0);
      }, []);

      
    const agregarProducto = useCallback(() => {
        if (productos.length >= 15) return;
        setProductos(current => [...current, { id: current.length + 1, titulo: "", precio: null, imagen: null }]);
    }, [productos]);
    
    const handleInputChange = (index, field, event) => {
        console.log(event); // Verificar que el evento está definido
        if (field === 'imagen') {
            const files = event.target.files;
            console.log(files); // Verificar que files está definido
            if (files && files.length > 0) {
                const file = files[0];
                console.log(file); // Verificar que file está definido
                const maxFileSize = 2 * 1024 * 1024; // 2MB
                if (file.size > maxFileSize) {
                    Alert({
                        tipo: 'error',
                        titulo: 'Error',
                        descripcion: 'El archivo supera el tamaño máximo de 2MB.',
                        duracion: 3000
                    });
                    return;
                }
                setProductos(current =>
                    current.map((producto, i) =>
                        i === index ? { ...producto, [field]: file } : producto
                    )
                );
            }
        } else {
            console.log(event.target.value); 
            setProductos(current =>
                current.map((producto, i) =>
                    i === index ? { ...producto, [field]: event.target.value } : producto
                )
            );
        }
    };
    

    const validarFormulario = () => {

        // Ejemplo simple de validación
        for (let producto of productos) {
            if (!producto.titulo || producto.precio <= 0) {
                Alert({
                    tipo: 'error',
                    titulo: 'Error',
                    descripcion: 'Todos los campos deben ser llenados correctamente',
                    duracion: 3000
                });
                return false;
            }
        }

        return true;
    };

    const enviarMenu = (event) => {
        event.preventDefault();
        if (!validarFormulario()) return;
        setLoading(true);
    
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!userDetails) {
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'Debe estar logueado para realizar esta acción.',
                duracion: 3000
            });
            setLoading(false);
            return;
        }
        const details = JSON.parse(userDetails);
        const jwt = details.jwt;
    
        const formData = new FormData();
        formData.append('idEvento', eventoId);
        productos.forEach((producto, index) => {
            formData.append('titulos', producto.titulo);
            formData.append('precios', producto.precio);
            if (producto.imagen && producto.imagen instanceof File) {
                formData.append('imagenes', producto.imagen, producto.imagen.name);
            }
        });
    
        fetch(variables.API_BASE_URL + '/api/menu/crear', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${jwt}` },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if(response.status === 413) {
                    throw new Error('El tamaño del archivo excede el máximo permitido.');
                }
                return response.text().then(text => { throw new Error(text || 'Algo salió mal en el servidor'); });
            }
            return response.json();
        })
        .then(async data => {
            await Alert({
                tipo: 'success',
                titulo: 'Éxito',
                descripcion: 'Menú creado con éxito',
                duracion: 3000
            });
            navigate("/panel-productor");
        })
        .catch(async error => {
            let errorMessage = 'Error desconocido';
            try {
                const errorData = JSON.parse(error.message);
                errorMessage = errorData.message || 'Error desconocido';
            } catch (e) {
                errorMessage = error.message;
            }
            await Alert({
                tipo: 'error',
                titulo: 'Error al crear el menú',
                descripcion: errorMessage,
                duracion: 5000
            });
        })
        .finally(() => {
            setLoading(false);
        });
    };
    
    return (
        <main className={styles.crearMenuBody}>
            <div className={styles.formularioMenu}>
                <h1>Crea tu menú virtual y empieza a vender las consumiciones de tu evento antes y durante el mismo.</h1>
                <h2><i className="fa-solid fa-list"></i></h2>
                <div style={{ borderTop: '1px solid #cdcdcd', boxSizing: 'border-box', width: '90%', height: '1px', margin: '10px' }}></div>
                <h4>¡Muy fácil!</h4>
                <p>Agrega hasta 15 productos a tu menú. Proporciona detalles como el nombre, precio y una imagen para cada producto.</p>
                <form onSubmit={enviarMenu}>
                    {productos.map((producto, index) => (
                        <div key={index} className={styles.producto}>
                            <h3>Producto {index + 1}</h3>
                            <input
                                type="text"
                                placeholder="Título del Producto"
                                maxLength="20"
                                value={producto.titulo || ''}
                                onChange={e => handleInputChange(index, 'titulo', e)}
                            />
                            <input
                                type="number"
                                placeholder="Precio sin $"
                                value={producto.precio || ''}
                                onChange={e => handleInputChange(index, 'precio', e)}
                            />
                            <input
                                type="file"
                                accept="image/png, image/jpeg, image/webp"
                                onChange={e => handleInputChange(index, 'imagen', e)}
                            />
                        </div>
                    ))}
                    <div className={styles.columnCenter}>
                        <button type="button" onClick={agregarProducto} disabled={productos.length === 15} className={styles.btnAgregarProducto}>
                            Agregar Producto <FontAwesomeIcon icon={faPlus} />
                        </button>
                        <SubmitButton
                            isDisabled={loading || !productos.every(p => p.titulo && p.precio > 0)}
                            color="#99419d"
                            isLoading={loading}
                            onClick={(e) => { e.preventDefault(); enviarMenu(e); }}
                        >
                            Crear Menú Virtual
                        </SubmitButton>
                    </div>
                </form>
            </div>
        </main>
    );
}

export default CrearMenuPage;