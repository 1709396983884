import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Alert from '../Alert/Alert';
import 'sweetalert2/src/sweetalert2.scss';
import variables from '../../Context/Variables';
import styles from './TerminosYCondicionesModal.module.scss';
import { useAuth } from '../../Context/AuthProvider';
import { Icon } from '@iconify/react/dist/iconify.js';

const TerminosYCondicionesModal = ({ show, handleClose }) => {
  const [terminosAceptados, setTerminosAceptados] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useAuth();

  useEffect(() => {
    setIsFormValid(terminosAceptados);
  }, [terminosAceptados]);

  const handleChange = (e) => {
    const { checked } = e.target;
    setTerminosAceptados(checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;

    setIsLoading(true);
    try {
      const token = localStorage.getItem('userDetails')
        ? JSON.parse(localStorage.getItem('userDetails')).jwt
        : JSON.parse(sessionStorage.getItem('userDetails')).jwt;

      await axios.post(
        `${variables.API_BASE_URL}/api/usuarios/aceptarTerminos`,
        { terminos: true },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const userDetails = JSON.parse(localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails'));
      const updatedUserDetails = {
        ...userDetails,
        necesitaAceptarTerminos: false
      };

      const storage = localStorage.getItem('userDetails') ? localStorage : sessionStorage;
      storage.setItem('userDetails', JSON.stringify(updatedUserDetails));

      await Alert({
        tipo: 'success',
        titulo: 'Términos aceptados',
        descripcion: 'Has aceptado los nuevos términos y condiciones.',
        duracion: 3000
      });

      handleClose();
    } catch (error) {
      await Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'Hubo un problema aceptando los términos. Por favor, inténtalo de nuevo.',
        duracion: 3000
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} backdrop="static" keyboard={false} className={styles.modalContent}>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>Términos y condiciones</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="terminos">
            <Form.Label className={styles.label}>Hemos actualizado los términos y condiciones de nuestra plataforma para mejorar tu experiencia. Para continuar, debes aceptarlos:</Form.Label>
            <div className={styles.containerCheckbox}>
              <input
                type="checkbox"
                id="modal_terminos"
                checked={terminosAceptados}
                onChange={handleChange}
                className={!terminosAceptados ? styles.inputError : styles.inputValid}
              />
              <label htmlFor="modal_terminos">
                <span>
                  He leído y acepto los{' '}
                  <a style={{ color: "#106bbd", textDecoration: "underline" }} href="/terminosYCondiciones" target="_blank" rel="noopener noreferrer">nuevos términos y condiciones</a>
                </span>
              </label>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!isFormValid || isLoading}
          className={styles.submitButton}
        >
          {isLoading ? 'Actualizando...' : 'Aceptar términos'}
        </Button>
        <Button
          variant="link"
          onClick={logout}
          className={styles.continueAsGuestButton}
          style={{ textDecoration: "none", color: "#1b1b1b", fontSize: "14px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
        >
          Cerrar sesión <Icon icon="carbon:chevron-right" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TerminosYCondicionesModal;