import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton';
import { useAuth } from '../../Context/AuthProvider';
import styles from './ProductosCaja.module.scss';
import variables from '../../Context/Variables';
import SkeletonProductoCaja from './SkeletonProductoCaja/SkeletonProductoCaja';
import Alert from '../../Components/Alert/Alert';

const defaultImageUrl = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Menus/food.webp";

const ProductosCaja = () => {
    const { eventoId, cajaId } = useParams();
    const { jwt } = useAuth();
    const [productos, setProductos] = useState([]);
    const [newProductos, setNewProductos] = useState([]);
    const [modifiedProductos, setModifiedProductos] = useState([]);
    const [deletedProductos, setDeletedProductos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [puedeSubirCambios, setPuedeSubirCambios] = useState(false);

    useEffect(() => {
        const fetchProductos = async () => {
            try {
                const response = await axios.get(variables.API_BASE_URL + `/api/cantina/${eventoId}/cajas/${cajaId}/productos`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`
                    }
                });
                setProductos(response.data.productos || []);
                setLoading(false);
            } catch (error) {
                Alert({
                    tipo: 'error',
                    titulo: 'Error',
                    descripcion: 'No se pudo cargar los productos de la caja.'
                });
                setLoading(false);
            }
        };

        fetchProductos();
    }, [eventoId, cajaId, jwt]);

    useEffect(() => {
        canSubmit();
    }, [newProductos, modifiedProductos, deletedProductos]);

    const handleInputChange = (index, value, field) => {
        const updatedProductos = [...productos];
        updatedProductos[index] = { ...updatedProductos[index], [field]: value };

        if (!updatedProductos[index].id || String(updatedProductos[index].id).startsWith("new-")) {
            // Manejo de nuevos productos
            setNewProductos(prev => {
                const exists = prev.find(p => p.id === updatedProductos[index].id);
                return exists ? prev.map(p => p.id === updatedProductos[index].id ? updatedProductos[index] : p) : [...prev, updatedProductos[index]];
            });
        } else if (!updatedProductos[index].tieneVentas) {  // Usar la propiedad `tieneVentas`
            // Manejo de productos modificados
            setModifiedProductos(prev => {
                const existsIndex = prev.findIndex(p => p.id === updatedProductos[index].id);
                if (existsIndex !== -1) {
                    // Si el producto ya está en modifiedProductos, actualizarlo
                    const updatedModifiedProductos = [...prev];
                    updatedModifiedProductos[existsIndex] = updatedProductos[index];
                    return updatedModifiedProductos;
                } else {
                    // Si el producto no está en modifiedProductos, agregarlo
                    return [...prev, updatedProductos[index]];
                }
            });
        }

        setProductos(updatedProductos);
    };

    const handleImageChange = (index, file) => {
        if (file && (file.type === "image/webp" || file.type === "image/jpeg" || file.type === "image/png")) {
            const updatedProductos = [...productos];
            updatedProductos[index] = { ...updatedProductos[index], imagen: file, imgURL: URL.createObjectURL(file) };

            const productId = String(updatedProductos[index].id);

            if (!productId || productId.startsWith("new-")) {
                setNewProductos(prev => {
                    const exists = prev.find(p => p.id === updatedProductos[index].id);
                    return exists ? prev.map(p => p.id === updatedProductos[index].id ? updatedProductos[index] : p) : [...prev, updatedProductos[index]];
                });
            } else if (!updatedProductos[index].tieneVentas) {
                setModifiedProductos(prev => {
                    const existsIndex = prev.findIndex(p => p.id === updatedProductos[index].id);
                    if (existsIndex !== -1) {
                        const updatedModifiedProductos = [...prev];
                        updatedModifiedProductos[existsIndex] = updatedProductos[index];
                        return updatedModifiedProductos;
                    } else {
                        return [...prev, updatedProductos[index]];
                    }
                });
            }

            setProductos(updatedProductos);
        } else {
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'Solo se permiten imágenes en formato webp, jpg o png.'
            });
        }
    };

    const handleAddNewProducto = () => {
        const nuevoProducto = {
            id: "new-" + new Date().getTime(),
            titulo: "",
            precio: "",
            imgURL: defaultImageUrl,
            imagen: null,
        };
        setProductos([...productos, nuevoProducto]);
        setNewProductos([...newProductos, nuevoProducto]);
    };

    const handleDeleteProducto = (index) => {
        const producto = productos[index];
        if (producto.tieneVentas) {  // Usar la propiedad `tieneVentas`
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'No se puede eliminar un producto que tiene ventas asociadas.'
            });
            return;
        }
        setDeletedProductos([...deletedProductos, producto.id]);
        const updatedProductos = productos.filter((_, idx) => idx !== index);
        setProductos(updatedProductos);
    };

    const canSubmit = () => {
        const allNewComplete = newProductos.length > 0 && newProductos.every(p => p.titulo && p.precio && p.imagen);
        const anyModified = modifiedProductos.length > 0;
        const anyDeleted = deletedProductos.length > 0;

        setPuedeSubirCambios(allNewComplete || anyModified || anyDeleted);

        return (
            allNewComplete ||
            anyModified ||
            anyDeleted
        );
    };

    const handleSubmit = async () => {
        if (!canSubmit()) {
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'Por favor, realice algún cambio antes de enviar.'
            });
            return;
        }

        setIsLoadingSubmit(true);

        try {
            const formData = new FormData();

            // Agregar productos nuevos
            newProductos.forEach((producto, index) => {
                formData.append(`nuevosProductos[${index}].titulo`, producto.titulo);
                formData.append(`nuevosProductos[${index}].precio`, producto.precio);
                if (producto.imagen) {
                    formData.append(`nuevosProductos[${index}].imagen`, producto.imagen);
                }
            });

            // Agregar productos modificados
            modifiedProductos.forEach(producto => {
                const updatedProducto = {
                    id: producto.id,
                    titulo: producto.titulo,
                    precio: producto.precio,
                    imagen: producto.imagen,
                };
                formData.append('productosActualizados', JSON.stringify(updatedProducto));
            });

            // Agregar productos eliminados
            deletedProductos.forEach(id => formData.append('productosAEliminar', id));

            await axios.post(variables.API_BASE_URL + `/api/cantina/${eventoId}/cajas/${cajaId}/actualizarProductos`, formData, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            Alert({
                tipo: 'success',
                titulo: 'Éxito',
                descripcion: 'Los productos han sido actualizados correctamente.'
            });
            window.location.reload();
        } catch (error) {
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'No se pudieron guardar los cambios.'
            });
        } finally {
            setIsLoadingSubmit(false);
        }
    };

    return (
        <div className={styles.body}>
            <div className={styles.header}>
                <button className={styles.volver} onClick={() => { window.history.back() }}>
                    <Icon width={30} icon="solar:arrow-left-linear" />
                </button>
            </div>
            <div className={styles.productosCajaContainer}>
                <h2>Gestión de Productos de la Caja</h2>
                <div className={styles.fondoDescripcion}>
                    <p>Desde acá podés agregar, editar o eliminar los productos que quieras vender en cantina.</p>
                </div>

                <div className={styles.productos}>
                    {loading ? (
                        Array.from({ length: 6 }).map((_, index) => (
                            <SkeletonProductoCaja key={index} />
                        ))
                    ) : (
                        productos.map((producto, index) => (
                            <div key={producto.id} className={styles.producto}>
                                <div className={styles.productImage}>
                                    <img
                                        src={producto.imgURL || defaultImageUrl}
                                        alt="Imagen del producto"
                                    />
                                    <Icon
                                        icon="uil:edit"
                                        width={35}
                                        className={styles.editIcon}
                                        onClick={() => document.getElementById(`fileInput-${index}`).click()}
                                    />
                                    <input
                                        type="file"
                                        id={`fileInput-${index}`}
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleImageChange(index, e.target.files[0])}
                                    />
                                </div>
                                <label>Título:</label>
                                <input
                                    type="text"
                                    value={producto.titulo}
                                    maxLength={12}
                                    onChange={(e) => handleInputChange(index, e.target.value, 'titulo')}
                                    disabled={producto.ventas && producto.ventas.length > 0}
                                    className={styles.inputProducto}
                                />
                                <label>Precio:</label>
                                <input
                                    type="number"
                                    value={producto.precio}
                                    onChange={(e) => handleInputChange(index, e.target.value, 'precio')}
                                    disabled={producto.ventas && producto.ventas.length > 0}
                                    className={styles.inputProducto}
                                />
                                {!producto.ventas || producto.ventas.length === 0 ? (
                                    <button
                                        className={styles.eliminarBtn}
                                        onClick={() => handleDeleteProducto(index)}
                                    >
                                        Eliminar
                                    </button>
                                ) : (
                                    <span className={styles.noEditable}>No editable (tiene ventas)</span>
                                )}
                            </div>
                        ))
                    )}
                    <button className={styles.agregarBtn} onClick={handleAddNewProducto}>
                        Agregar nuevo producto <Icon icon="mdi:plus-circle-outline" />
                    </button>
                </div>
                <SubmitButton
                    isDisabled={!puedeSubirCambios}
                    isLoading={isLoadingSubmit}
                    onClick={handleSubmit}
                    className={styles.submitButton}
                >
                    Guardar cambios
                </SubmitButton>
            </div>
        </div>
    );
};

export default ProductosCaja;