import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <>
      <footer className={styles.footerContainer}>
        {/* Logo */}
        <article className={styles.logoContainer}>
          <h1 className={styles.logo}>
            <span className={styles.logoSimple}>simple</span>
            <span className={styles.logoPass}>pass</span>
          </h1>
        </article>

        {/* Enlaces */}
        <article className={styles.linkContainer}>
          <Link className={styles.link} to="/nosotros">
            ¿Quiénes somos?
          </Link>
          <Link className={styles.link} to="/signup-productor">
            Registrarme como productor
          </Link>
          <Link className={styles.link} to="/faq">
            Preguntas frecuentes
          </Link>
          <Link className={styles.link} to="/terminosYCondiciones">
            Términos y Condiciones
          </Link>
          <Link className={styles.link} to="/trabaja-con-nosotros">
            Unite al equipo
          </Link>
        </article>

        <div className={styles.linea}></div>

        {/* Parte inferior con redes sociales y copyright */}
        <article className={styles.footerBottom}>
          <span className={styles.copyright}>© 2024 Copyright, HERGON SOFT S.A.S todos los derechos reservados.</span>
          <div className={styles.socialMediaContainer}>
            <a href="https://www.facebook.com/profile.php?id=100076488752786" target="_blank" rel="noopener noreferrer">
              <Icon width={25} icon="fa6-brands:facebook" />
            </a>
            <a href="https://www.instagram.com/simplepass.com.ar?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">
              <Icon width={25} icon="bi:instagram" />
            </a>
            <a href="https://www.linkedin.com/company/hergon-soft-s-a-s" target="_blank" rel="noopener noreferrer">
              <Icon width={25} icon="bi:linkedin" />
            </a>
          </div>
        </article>
      </footer>
    </>
  );
};

export default Footer;
