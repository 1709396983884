import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from '../../Components/Alert/Alert';
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton';
import styles from './NuevaContraseñaPage.module.scss';
import variables from '../../Context/Variables';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

const NuevaContrasenaPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    document.title = "Nueva Contraseña";
  }, []);

  useEffect(() => {
    validateForm();
  }, [newPassword, confirmNewPassword]);

  const validateForm = () => {
    const newErrors = {};
    if (!validarContraseña(newPassword)) {
      newErrors.newPassword = "La contraseña debe tener al menos 6 caracteres, incluir una mayúscula y una minúscula.";
    }
    if (newPassword !== confirmNewPassword) {
      newErrors.confirmNewPassword = "Las contraseñas no coinciden.";
    }
    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  };

  const validarContraseña = (contraseña) => {
    const regexContraseña = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    return regexContraseña.test(contraseña);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${variables.API_BASE_URL}/api/usuarios/changePassword`, {
        token,
        newPassword
      });

      await Alert({
        tipo: 'success',
        titulo: 'Cambio exitoso',
        descripcion: response.data,
        duracion: 3000
      });
      navigate("/login");
    } catch (error) {
      if (error.response && error.response.data) {
        await Alert({
          tipo: 'error',
          titulo: 'Error',
          descripcion: error.response.data,
          duracion: 5000
        });
      } else {
        await Alert({
          tipo: 'error',
          titulo: 'Error',
          descripcion: 'Error al actualizar la contraseña.',
          duracion: 5000
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <section className={styles.bodyCambiarContraseña}>
      <div className={styles.fotoCont}>
        <img src="" alt="" />
      </div>
      <div className={styles.registroForm}>
        <h2>Nueva Contraseña</h2>
        <form
          id="changePasswordForm"
          onSubmit={handleSubmit}
          className={styles.form}
        >
          <div className={styles.formGroup}>
            <label htmlFor="newPassword">Nueva Contraseña</label>
            <div className={styles.inputGroup}>
              <input
                type={showPassword ? "text" : "password"}
                id="newPassword"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className={styles.input}
              />
              <button
                type="button"
                className={styles.togglePassword}
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            {errors.newPassword && <p className={styles.error}>{errors.newPassword}</p>}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="confirmNewPassword">Confirmar Nueva Contraseña</label>
            <div className={styles.inputGroup}>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmNewPassword"
                required
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                className={styles.input}
              />
              <button
                type="button"
                className={styles.togglePassword}
                onClick={toggleConfirmPasswordVisibility}
              >
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            {errors.confirmNewPassword && <p className={styles.error}>{errors.confirmNewPassword}</p>}
          </div>
          <SubmitButton
            type="submit"
            isDisabled={!isFormValid || isLoading}
            color={"#252525"}
            isLoading={isLoading}
          >
            Cambiar Contraseña
          </SubmitButton>
        </form>
      </div>
    </section>
  );
};

export default NuevaContrasenaPage;
