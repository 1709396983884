import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SubmitButton from '../Buttons-loaders-inputs/SubmitButton/SubmitButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "./EnvioDeQrForm.module.scss";
import variables from "../../Context/Variables";
import Alert from '../Alert/Alert';

const EnvioDeQrForm = () => {
  const { eventoId, tipoDeTicketId, nombreEvento, nombreTicket } = useParams();
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [cantidad, setCantidad] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [nombreCompleto, setNombreCompleto] = useState('');
  const [dni, setDni] = useState('');
  const navigate = useNavigate();

  const safeDoubleDecodeURIComponent = (component) => {
    try {
      return decodeURIComponent(decodeURIComponent(component)); 
    } catch (error) {
      console.error('Error decoding URI component:', component, error);
      return "-";  
    }
  };

  const decodedNombreEvento = safeDoubleDecodeURIComponent(nombreEvento);
  const decodedNombreTicket = safeDoubleDecodeURIComponent(nombreTicket);

  const ajustarCantidad = (cambio) => {
    setCantidad(prev => Math.max(0, Math.min(5, prev + cambio)));
  };

  const validarNombre = (nombre) => {
    return nombre.length >= 4 && nombre.length <= 30;
  };

  const validarDNI = (dni) => {
    const regexDNI = /^\d{7,15}$/;
    return regexDNI.test(dni);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email !== confirmEmail) {
      Alert({
        tipo: 'warning',
        titulo: 'Oops...',
        descripcion: 'Los emails no coinciden. Por favor, verifica y trata nuevamente.',
        duracion: 5000
      });
      return;
    }

    if (!validarDNI(dni)) {
      Alert({
        tipo: 'error',
        titulo: 'DNI Inválido',
        descripcion: 'El DNI debe tener entre 7 y 15 números sin espacios ni letras.',
        duracion: 5000
      });
      return;
    }

    if (!validarNombre(nombreCompleto)) {
      Alert({
        tipo: 'error',
        titulo: 'Nombre Inválido',
        descripcion: 'El nombre debe tener entre 4 y 30 caracteres.',
        duracion: 3000
      });
      return;
    }

    setIsLoading(true);
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    const token = userDetails ? JSON.parse(userDetails).jwt : null;

    try {
      const response = await axios.post(`${variables.API_BASE_URL}/api/tickets/enviar-tickets`, null, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          eventoId,
          tipoDeTicketId,
          email,
          cantidad,
          nombreCompleto,
          dni
        }
      });

      if (response.status === 200) {
        Alert({
          tipo: 'success',
          titulo: '¡Enviado!',
          descripcion: 'Tickets enviados correctamente.',
          duracion: 5000
        }).then(() => {
          Alert({
            tipo: 'warning',
            titulo: '¿Qué deseas hacer?',
            descripcion: '¿Ir al Panel de Control o Volver a Generar?',
            duracion: 0 // Duración indefinida para que el usuario pueda elegir
          }).then((result) => {
            if (result === 'Cerrar') {
              navigate('/panel-productor');
            } else {
              navigate(`/enviar-qrs/${eventoId}`);
            }
          });
        });
      } else {
        throw new Error('No se pudo enviar los tickets correctamente.');
      }
    } catch (error) {
      Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'Error al enviar los tickets: ' + (error.response?.data || error.message),
        duracion: 4000
      });
      console.error('Error al enviar tickets:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.envioQrForm}>
      <h2 className={styles.titulo}>Configuración y envío de QRs</h2>
      <h3 className={styles.subtitulo}>Evento: <span>{decodedNombreEvento}</span></h3>
      <h3 className={styles.subtitulo}>Tipo de ticket: <span>{decodedNombreTicket}</span></h3>
      <form onSubmit={handleSubmit} className={styles.formulario}>
        <div className={styles.inputGroup}>
          <label htmlFor="email" className={styles.label}>Email:</label>
          <input
            type="email"
            id="email"
            placeholder='Donde se recibirán los tickets'
            className={styles.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" // Simple Email regex
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="confirmEmail" className={styles.label}>Confirmar Email:</label>
          <input
            type="email"
            id="confirmEmail"
            placeholder='Repetir el email por seguridad'
            className={styles.input}
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            required
            onPaste={(e) => e.preventDefault()}
          />
        </div>
        {/* Campo para el Nombre Completo */}
        <div className={styles.inputGroup}>
          <label htmlFor="nombreCompleto" className={styles.label}>Nombre Completo:</label>
          <input
            type="text"
            id="nombreCompleto"
            placeholder='Nombre del quien los utilizará'
            className={styles.input}
            value={nombreCompleto}
            onChange={(e) => setNombreCompleto(e.target.value)}
            required
          />
        </div>

        {/* Campo para el DNI */}
        <div className={styles.inputGroup}>
          <label htmlFor="dni" className={styles.label}>DNI/Pasaporte:</label>
          <input
            type="text"
            id="dni"
            placeholder='DNI de quien los utilizará'
            className={styles.input}
            value={dni}
            onChange={(e) => setDni(e.target.value)}
            required
          />
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="cantidad" className={styles.label}>Cantidad:</label>
          <div className={styles.cantidadControles}>
            <button type="button" className={styles.boton} onClick={() => ajustarCantidad(-1)} disabled={cantidad <= 0}><FontAwesomeIcon icon={faMinus} /></button>
            <input
              type="number"
              id="cantidad"
              className={styles.inputCantidad}
              value={cantidad}
              readOnly
            />
            <button type="button" className={styles.boton} onClick={() => ajustarCantidad(1)} disabled={cantidad >= 5}><FontAwesomeIcon icon={faPlus} /></button>
          </div>
        </div>
        <SubmitButton
          isDisabled={email === '' || confirmEmail === '' || email !== confirmEmail || nombreCompleto === '' || dni === '' || cantidad <= 0 || cantidad > 5 || isLoading}
          color="#99419d"
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Enviar QRs
        </SubmitButton>
      </form>
    </div>
  );
};

export default EnvioDeQrForm;



